<template>
  <div class="all">
    <!--   <nav-bar class="detail-nav"> 
    <div class="title" slot="center">
      <div slot="center">登记入职</div>
    </div>
  </nav-bar> -->
    <div class="content">
      <div class="header" :style="{ backgroundImage: 'url(\'' + img + '\')' }"></div>
      <van-steps :active="active" active-color="rgb(243, 40, 71)">
        <van-step>基本信息</van-step>
        <van-step>账户信息</van-step>
        <van-step>其他信息</van-step>
      </van-steps>
    <van-form ref="submitForm" @submit="addBankInfo" >
      <div class="body" v-if="showBaseinfo">
        <van-cell-group>
          <van-cell>
            <p class="headerTitle">请仔细填写您的基本信息</p>
          </van-cell>
        </van-cell-group>
        <van-field
          v-model="form.name"
          rows="1"
          autosize
          label="姓名"
          class="field"
          input-align="right"
          placeholder="请填写您的姓名"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-model="form.mobile"
          rows="1"
          autosize
          label="手机号码"
          class="field"
          input-align="right"
          placeholder="请填写您的手机号码"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-model="form.id_card"
          rows="1"
          autosize
          label="身份证号"
          class="field"
          input-align="right"
          placeholder="请填写您的身份证号码"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field name="sex" label="性别" class="field" input-align="right" >
          <template #input>
            <van-radio-group v-model="form.sex" direction="horizontal">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="form.email"
          rows="1"
          autosize
          label="邮箱"
          class="field"
          input-align="right"
          placeholder="请填写您的邮箱"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          readonly
          clickable
          label="入职工厂"
          input-align="right"
          placeholder="点击选择入职工厂"
          :value="factory_name"
          @click="FactoryPicker = true"
          :rules="[{ required: true, message: '' }]"
        />
        <van-popup v-model="FactoryPicker" position="bottom">
          <van-picker
            show-toolbar
            title="选择入职工厂"
            value-key="name"
            :columns="factoriesList"
            @confirm="ConfirmFactory"
            @cancel="FactoryPicker = false"
          />
        </van-popup>
        <van-field
          readonly
          clickable
          label="户口性质"
          input-align="right"
          placeholder="点击选择户口性质"
          :value="form.census"
          @click="CensusPicker = true"
          :rules="[{ required: true, message: '' }]"
        />
        <van-popup v-model="CensusPicker" position="bottom">
          <van-picker
            show-toolbar
            title="选择户口性质"
            value-key="name"
            :columns="CensusList"
            @confirm="ConfirmCensus"
            @cancel="CensusPicker = false"
          />
        </van-popup>
         <van-field
          v-model="form.hometown"
          rows="1"
          autosize
          label="户口地址"
          type="textarea"
          class="field"
          input-align="right"
          placeholder="请填写户口地址"
          :rules="[{ required: true, message: '' }]"
        />
        <van-button class="commit" >下一步</van-button>
      </div>
      </van-form>

      <van-form ref="submitForm" @submit="addotherInfo" >
      <div class="base" v-if="showBankinfo">
        <van-cell-group>
          <van-cell>
            <p class="headerTitle">请仔细填写您的银行账户信息</p>
          </van-cell>
        </van-cell-group>
        <van-field
          v-model="form.bank_name"
          rows="1"
          autosize
          label="开户银行"
          class="field"
          input-align="right"
          placeholder="请填写你的开户行"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-model="form.bank_account"
          rows="1"
          autosize
          label="银行卡号"
          class="field"
          input-align="right"
          placeholder="请填写你的银行卡号"
          :rules="[{ required: true, message: '' }]"
        />
        <van-button class="commit">下一步</van-button>
      </div>
      </van-form>
      
      <van-form ref="submitForm" @submit="onSubmit" >
      <div class="body" v-if="showOtherinfo">
        <van-cell-group>
          <van-cell>
            <p class="headerTitle">请填写其他信息</p>
          </van-cell>
        </van-cell-group>
        <van-field
          v-model="form.c_name"
          rows="1"
          autosize
          label="紧急联系人"
          class="field"
          input-align="right"
          placeholder="请填写紧急联系人"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-model="form.contact"
          rows="1"
          autosize
          label="紧急联系号码"
          class="field"
          input-align="right"
          placeholder="请填写紧急联系号码"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          readonly
          clickable
          label="学历"
          input-align="right"
          placeholder="点击选择学历"
          :value="form.edu"
          @click="EduPicker = true"
          :rules="[{ required: true, message: '' }]"
        />
        <van-popup v-model="EduPicker" position="bottom">
          <van-picker
            show-toolbar
            title="选择个人学历"
            value-key="name"
            :columns="EduList"
            @confirm="ConfirmEdu"
            @cancel="EduPicker = false"
          />
        </van-popup>
        <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="form.start_at"
          label="入职时间"
          input-align="right"
          class="field"
          placeholder="点击选择入职时间"
          @click="StartTimePicker = true"
          :rules="[{ required: true, message: '' }]"
        />
        <van-calendar v-model="StartTimePicker" @confirm="ConfirmStartTime" />
        <van-field
          v-model="form.ready_at"
          readonly
          clickable
          label="转正时间"
          class="field"
          input-align="right"
          placeholder="请填写转正时间"
          @click="ReadyTimePicker = true"
          :rules="[{ required: true, message: '' }]"
        />
        <van-popup v-model="ReadyTimePicker" position="bottom">
          <van-datetime-picker
            type="date"
            :min-date="minDate"
            @confirm="ConfirmReadyTime"
            @cancel="ReadyTimePicker = false"
          />
        </van-popup>
        <van-field
          v-model="form.contract_at"
          readonly
          clickable
          label="合同到期时间"
          class="field"
          input-align="right"
          placeholder="请填写合同到期时间"
          @click="ContractTimePicker = true"
          :rules="[{ required: true, message: '' }]"
        />
        <van-popup v-model="ContractTimePicker" position="bottom">
          <van-datetime-picker
            type="date"
            :min-date="minDate"
            @confirm="ConfirmContractTime"
            @cancel="ContractTimePicker = false"
          />
        </van-popup>
        <van-field name="sex" label="是否购买社保" class="field" input-align="right" >
          <template #input>
            <van-radio-group v-model="form.social_status" direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="sex" label="是否购买公积金" class="field" input-align="right" >
          <template #input>
            <van-radio-group v-model="form.pro_status" direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-button class="commit">立即提交</van-button>
      </div>
      </van-form>
  </div>  
  </div>
</template>

<script>
// import NavBarBack from 'common/navbar/NavBarBack'
// import {getVisitById,addVisitor} from "network/visitor";
import NavBar from "common/navbar/NavBar";
import { userBackup, globalData } from "network/others";
import {
  Cell,
  CellGroup,
  Field,
  Button,
  DatetimePicker,
  Calendar,
  Popup,
  Picker,
  Toast,
  Radio,
  RadioGroup,
  Step, 
  Steps ,
  Form,
} from "vant";
export default {
  data() {
    return {
      active: 0,
      form: {
        name: '',
        mobile: '',
        id_card: '',
        email: '',
        census: '',
        hometown: '',
        c_name: '',
        contact: '',
        sex: '',
        bank_name: '',
        bank_account: '',
        factory_id: '',
        contract_at:'',
        ready_at: '',
        start_at: '',
        social_status: '',
        pro_status: '',
        edu: '',
      },
      factory_name: '',
      edu_name: '',
      img: require("@/assets/images/commit-info/application.png"),
      showBaseinfo: true,
      showBankinfo: false,
      showOtherinfo: false,
      CensusPicker: false, 
      FactoryPicker: false,
      EduPicker: false,
      StartTimePicker:false,
      ContractTimePicker:false,
      ReadyTimePicker:false,
      CensusList:[],
      factoriesList: [],
      EduList:[],
      minDate: new Date(2021, 0, 1),
      currentDate: new Date(),
    };
  },
  created() {
    this.loadData();
  },
  components: {
    NavBar,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Calendar.name]: Calendar,
    [Form.name]: Form,
  },

  methods: {
    loadData() {
      globalData().then((res)=>{
        this.CensusList = res.data.list.CensusList;
        this.factoriesList = res.data.factories;
        this.EduList = res.data.list.eduList;
      })
    },
    checkPhone(phone) {
      // var phone = document.getElementById('phone').value;
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        return false;
      } else {
        return true;
      }
    },
    addBankInfo() {
      if (!this.form.sex) {
        Toast.fail("请选择性别");
        return;
      }
      if (!this.checkPhone(this.form.mobile)) {
        Toast.fail("手机号码格式有误");
        return;
      }
      this.showBaseinfo = false;
      this.showBankinfo = true;
      this.active = 1;
    },
    addotherInfo() {
      this.showBankinfo = false;
      this.showOtherinfo = true;
      this.active = 2;
    },
    ConfirmCensus(value) {
      this.form.census = value.name;
      this.CensusPicker = false;
    },
    ConfirmFactory(value) {
      this.form.factory_id = value.id;
      this.factory_name = value.name;
      this.FactoryPicker = false;
    },
    ConfirmEdu(value){
      this.form.edu = value.name;
      this.EduPicker = false;
    },
    ConfirmStartTime(date){
      this.form.start_at= this.formatDate(date);
      this.StartTimePicker = false;
    },
    ConfirmContractTime(date){
     this.form.contract_at = this.formatDate(date);
    this.ContractTimePicker = false;
    },
    ConfirmReadyTime(date){
      this.form.ready_at = this.formatDate(date);
      this.ReadyTimePicker = false;
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onSubmit(){
      if (!this.form.social_status) {
        Toast.fail("请选择是否购买社保");
        return;
      };
      if (!this.form.pro_status) {
        Toast.fail("请选择是否购买公积金");
        return;
      };
      userBackup(this.form).then((res)=>{
        if(res.success){
          Toast.success("申请成功！")
          this.$router.push({
              path: "/sucefulResult",
              query: { title: "我要应聘" },
            });
        }
      })
    },
  },
};
</script>

<style scoped>
.commit {
  width: 60%;
  background-image: linear-gradient(
    to right,
    rgb(243, 70, 31),
    rgb(243, 40, 71)
  );
  color: #fff;
  border: none;
  margin: 20px 20%;
}
.headerTitle {
  font-size: 15px;
}
.field {
  display: flex;
  align-items: center;
}
.all {
  width: 100%;
}
.content {
  width: 100%;
}
.header {
  height: 130px;
  background-repeat: no-repeat;
  background-size: 100%;
  /* background-image: url("~assets/images/commit-info/application.png"); */
}
</style>
// 